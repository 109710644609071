// Footer.js
import React from "react";
import "./Footer.css";
import { FaGithub, FaEnvelope, FaPhone, FaHome } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer" className="footer-container"> {/* Ensure ID is "footer" */}
      <div className="footer-hero">
        <h2>Let’s Build Something Amazing Together!</h2>
        <p>Join me on this journey of innovation and creativity. Got ideas? Let’s make them a reality. Feel free to reach out anytime!</p>
      </div>

      <div className="footer-content">
        {/* Contact Section */}
        <div className="footer-section">
          <h3>Contact</h3>
          <p><FaEnvelope /> Email: <a href="mailto:herbfarmermail@proton.me">herbfarmermail@proton.me</a></p>
          <p><FaPhone /> Phone: <a href="tel:0123123456789">+423/586427850</a></p>
          <p><FaHome /> Location: Virtual Space 🌐</p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#ideas">Ideas</a></li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div className="footer-section">
          <h3>Follow Me</h3>
          <div className="social-icons">
            <a href="https://github.com/HerbFarmer" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="footer-version">v1.2</p>
        <p>© 2024 Herbfarmer.me | All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
