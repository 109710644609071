// Navbar.js
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const navigate = useNavigate();
  const isClickingRef = useRef(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };



  const smoothScrollTo = (sectionId) => {
    const element = document.getElementById(sectionId);
    const navbarHeight = document.querySelector(".navbar").offsetHeight || 70;
  
    if (element) {
      // Define custom offsets for specific sections
      let offset;
      switch (sectionId) {
        case "about":
          offset = navbarHeight + 80; // Extra space for About Me section
          break;
        case "projects":
          offset = navbarHeight - 5; // Slightly reduced space for Projects section
          break;
        default:
          offset = navbarHeight; // Default offset for other sections
          break;
      }
  
      const targetPosition = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
  };
  
  
  
  



  // Enhanced scroll detection with top of the page check
  useEffect(() => {
    const sectionIds = ["home", "about", "projects", "ideas", "footer"];
    let lastDetectedSection = "home";

    const handleScroll = () => {
      if (isClickingRef.current) return;

      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let activeId = lastDetectedSection;

      if (window.scrollY < 100) {
        activeId = "home";
      } else {
        sectionIds.forEach((id) => {
          const section = document.getElementById(id);
          if (section) {
            const offsetTop = section.offsetTop;
            const offsetBottom = offsetTop + section.offsetHeight;

            if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
              activeId = id;
              lastDetectedSection = id;
            }
          }
        });
      }

      if (activeSection !== activeId) {
        setActiveSection(activeId);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  // Handle logo click to refresh the page
  const handleLogoClick = () => {
    navigate("/#home", { replace: true });
    setActiveSection("home");
    window.location.href = "/";
  };

 // Unified link click handler
  const handleLinkClick = (sectionId) => {
  isClickingRef.current = true;
  setMobileMenuOpen(false);
  setActiveSection(sectionId);

  // Use smoothScrollTo for all sections, including Home
  smoothScrollTo(sectionId);

  // Reset the click reference after the animation
  setTimeout(() => {
    isClickingRef.current = false;
  }, 1200);
};


  return (
    <nav className="navbar">
      <div className="navbar-logo" onClick={handleLogoClick}>
        <img src={`${process.env.PUBLIC_URL}/img/CityBoy.png`} alt="HerbFarmer Logo" />
        <span>HerbFarmer</span>
      </div>

      <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
        <li>
          <Link
            to="/#home"
            className={`navbar-link ${activeSection === "home" ? "active" : ""}`}
            onClick={() => handleLinkClick("home")}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/#about"
            className={`navbar-link ${activeSection === "about" ? "active" : ""}`}
            onClick={() => handleLinkClick("about")}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/#projects"
            className={`navbar-link ${activeSection === "projects" ? "active" : ""}`}
            onClick={() => handleLinkClick("projects")}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to="/#ideas"
            className={`navbar-link ${activeSection === "ideas" ? "active" : ""}`}
            onClick={() => handleLinkClick("ideas")}
          >
            Ideas
          </Link>
        </li>
        <li>
          <Link
            to="/#footer"
            className={`navbar-link ${activeSection === "footer" ? "active" : ""}`}
            onClick={() => handleLinkClick("footer")}
          >
            Footer
          </Link>
        </li>
      </ul>

      <div className="hamburger" onClick={toggleMobileMenu}>
        <img src="/icons/hamburgerwhite.png" alt="menu" className="hamburger-icon" />
      </div>
    </nav>
  );
};

export default Navbar;
